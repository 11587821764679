import React from 'react';
import { Dimensions } from 'react-native';

let fontSizeHuge = 64;
let fontSizeSecondaryHeading = 28;
let paddingsNormal = 20;
let fontSizeBig = 32;
let marginNormal = 25;
let buttonHeight = 79;
let buttonFontSize = 15;
if (Dimensions.get('window').width <= 360) {
    fontSizeHuge = 44;
    fontSizeSecondaryHeading = 24;
    paddingsNormal = 10;
    fontSizeBig = 30;
    marginNormal = 20;
    buttonHeight = 59;
    buttonFontSize = 16;
}

export default {
    fontSize: {
        huge: fontSizeHuge,
        big: fontSizeBig,
        secondaryHeading: fontSizeSecondaryHeading,
    },
    paddings: {
        normal: paddingsNormal,
    },
    margins: {
        normal: marginNormal,
    },
    buttons: {
        height: buttonHeight,
        fontSize: buttonFontSize,
    }
};
