import React from 'react';
import {ImageBackground, Text, StyleSheet, TouchableOpacity} from "react-native";
import Colors from "../../res/constants/Colors";

export default function Button(props) {
    return (
        <TouchableOpacity onPress={props.onPress} style={{...styles.touchableOpacity, ...props.wrapperStyle}}>
            {props.beforeText || <></>}
            <Text style={{...styles.text, ...props.style}}>{props.label}</Text>
            {props.afterText || <></>}
        </TouchableOpacity>
    );
}

const styles = {
    touchableOpacity: {
        overflow: 'hidden',
        backgroundColor: Colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15,
        paddingVertical: 5,
        flexDirection: 'row',
    },
    text: {
        color: '#FFF',
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 20,
        textAlign: 'center',
    },
};
