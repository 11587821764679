import React from 'react';
import {
    Platform,
} from "react-native";
import {NavigationContainer} from "@react-navigation/native";
import {navigationRef} from "./RootNavigation";
import Landing from "./screens/Landing";
import BeforeStart from "./screens/BeforeStart";
import Measure from "./screens/Measure";
import {connect} from "react-redux";
import {createStackNavigator} from "@react-navigation/stack";
import {setMenu} from "./redux/actions/MenuActions";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import {setUser} from "./redux/actions/UserActions";
import AddMember from "./screens/AddMember";
import Analysis from "./screens/Analysis";
import ProductSuggestion from "./screens/ProductSuggestion";
import FreeAdvising from "./screens/FreeAdvising";
// import Toast from 'react-native-toast-message'
import Menu from "./components/Menu";
import PastAnalysis from "./screens/PastAnalysis";
import LegalPoints from "./screens/LegalPoints";
import SeeMore from "./screens/SeeMore";

const Stack = createStackNavigator();

function NavigatorWrapper(props) {
    return (
        <>
            <Menu />
            <NavigationContainer ref={navigationRef}>
                <Stack.Navigator
                    screenOptions={{
                        headerShown: false,
                    }}
                    initialRouteName={"Landing"}
                >
                    <Stack.Screen name={"Landing"} component={Landing} />
                    <Stack.Screen name={"BeforeStart"} component={BeforeStart} />
                    <Stack.Screen name={"Measure"} component={Measure} />
                    <Stack.Screen name={"Login"} component={Login} />
                    <Stack.Screen name={"SignUp"} component={SignUp} />
                    <Stack.Screen name={"AddMember"} component={AddMember} />
                    <Stack.Screen name={"Analysis"} component={Analysis} />
                    <Stack.Screen name={"ProductSuggestion"} component={ProductSuggestion} />
                    <Stack.Screen name={"FreeAdvising"} component={FreeAdvising} />
                    <Stack.Screen name={"PastAnalysis"} component={PastAnalysis} />
                    <Stack.Screen name={"LegalPoints"} component={LegalPoints} />
                    <Stack.Screen name={"SeeMore"} component={SeeMore} />
                </Stack.Navigator>
            </NavigationContainer>
            {/*<Toast ref={(ref) => Toast.setRef(ref)} />*/}
        </>
    );
}

const mapStateToProps = state => {
    return {
        menuState: state.MenuReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (payload) => dispatch(setMenu(payload)),
        setUser: (payload) => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorWrapper);
