// import * as SecureStore from 'expo-secure-store';

export const setUser = (value) => {
    return {
        type: "SET_USER",
        payload: value,
    }
}

// export const loginUser = (value) => {
//     SecureStore.setItemAsync("userData", value);
//     return {
//         type: 'LOGIN_USER',
//         payload: value,
//     }
// }
//
// export const initUser = () => {
//     const value = SecureStore.getItemAsync('userData');
//     return {
//         type: 'INIT_USER',
//         payload: value,
//     }
// }
