import React, {useState} from 'react';
import {
    Dimensions,
    Linking,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import Space from "../components/Space";
import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import ShareButton from "../components/HeaderButtons/ShareButton";
import AutoHeightImage from "../components/AutoHeightImage";
import CloseMenuButton from "../components/HeaderButtons/CloseMenuButton";
import {navigate} from "../RootNavigation";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function productSuggestion(props) {
    const translations = props.translations[props.translations.currentLanguage];
    const imageWidth = Dimensions.get('window').width - 40;

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton />)}
                headerCenter={() => (<HeaderLogo />)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <Space />
            <Text style={styles.screenHeading}>NO HEAT - NO LIVE</Text>
            <Space />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-1.png')} />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-2.png')} />
            <Space />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-3.png')} />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-4.png')} />
            <Space />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-5.png')} />
            <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-6.png')} />
            <Space />
            <TouchableOpacity onPress={() => {
                Linking.openURL('https://chaava.com');
            }}>
                <AutoHeightImage width={imageWidth} source={require('../../res/images/see-more/see-more-7.png')} />
            </TouchableOpacity>
            <Space height={20} />
            <TouchableOpacity onPress={() => {
                Linking.openURL('https://data212.com');
            }} style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 15,
            }}>
                <Text style={{
                    color: '#FFF',
                    fontSize: 18,
                    fontFamily: 'Montserrat_600SemiBold',
                    marginRight: 10,
                }}>Produced By</Text>
                <AutoHeightImage width={100} source={require('../../res/images/data-212-logo.png')} />
            </TouchableOpacity>
            <Space height={20} />
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(productSuggestion);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    screenHeading: {
        fontSize: 28,
        fontFamily: 'Montserrat_800ExtraBold',
        color: '#FFF',
        textAlign: 'center',
    },
    content: {
        fontSize: 20,
        fontFamily: 'Montserrat_400Regular',
        color: '#FFFFFF',
        paddingHorizontal: 20,
        marginBottom: 20,
        width: 100 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 90 + '%',
        marginVertical: 20,
    },
    button: {
        width: 48 + '%',
    },
};
