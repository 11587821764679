import React from 'react';
import Button from '../Button';
import {StyleSheet} from 'react-native';
import { navigate } from '../../RootNavigation';
import {connect} from 'react-redux';
import {setUser} from "../../redux/actions/UserActions";
import StyleGuide from "../../../res/constants/StyleGuide";
import AsyncStorage from '@react-native-async-storage/async-storage';

function LoginButton(props) {
    const translations = props.translations[props.translations.currentLanguage];
    return (
        <>
        {
            props.user.isLoggedIn ?
                <Button onPress={() => {
                    AsyncStorage.removeItem('userData').then(() => {
                        props.setUser({
                            isLoggedIn: false,
                            data: {},
                        });
                    }).catch(err => {
                        console.log("LOGOUT ERROR", err);
                    });
                    navigate('Landing');
                }} label={translations.general.logout} wrapperStyle={styles.buttonWrapper} style={styles.buttonText} /> :
                <Button onPress={() => {
                    navigate('Login');
                }} label={translations.general.loginButton} wrapperStyle={styles.buttonWrapper} style={styles.buttonText} />
        }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);

const styles = StyleSheet.create({
    buttonWrapper: {
        width: 30 + '%',
        height: StyleGuide.buttons.height,
    },
    buttonText: {
        fontSize: StyleGuide.buttons.fontSize,
    },
});
