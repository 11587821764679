import React, {useState} from 'react';
import {
    Dimensions,
    Linking,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import Space from "../components/Space";
import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import ShareButton from "../components/HeaderButtons/ShareButton";
import AutoHeightImage from "../components/AutoHeightImage";
import CloseMenuButton from "../components/HeaderButtons/CloseMenuButton";
import {navigate} from "../RootNavigation";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function productSuggestion(props) {
    const translations = props.translations[props.translations.currentLanguage];

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<CloseMenuButton onPress={() => {navigate("Landing")}} />)}
                headerCenter={() => (<HeaderLogo />)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <Space />
            <Text style={styles.screenHeading}>{translations.legalPoints.heading}</Text>
            <Space />
            <Text style={styles.content}>
                {translations.legalPoints.content}
            </Text>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(productSuggestion);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    screenHeading: {
        fontSize: 24,
        fontFamily: 'Montserrat_800ExtraBold',
        color: '#FFF',
        textAlign: 'center',
    },
    content: {
        fontSize: 18,
        fontFamily: 'Montserrat_400Regular',
        color: '#FFFFFF',
        paddingHorizontal: 20,
        marginBottom: 20,
        width: 100 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 90 + '%',
        marginVertical: 20,
    },
    button: {
        width: 48 + '%',
    },
};
