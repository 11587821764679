import React, {useState} from 'react';
import {ActivityIndicator, Dimensions, Image, Image as RNImage} from "react-native";

export default function AutoHeightImage(props) {
    const [height, setHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    let imageWidth = typeof props.width === 'number' ? props.width : 0;
    if (typeof props.width === 'string' && props.width.slice(-1) === '%') {
        const widthRatio = parseFloat(props.width) / 100;
        imageWidth = Dimensions.get('window').width * widthRatio;
    }

    RNImage.getSize(props.source, (width, height) => {
        const ratio = width / height;
        const newHeight = imageWidth / ratio;
        setHeight(newHeight);
    });

    return (
        <>
            {loading && (
                <ActivityIndicator />
            )}
            {/*<Image {...props} style={[props.style, {width: imageWidth, height}]} onLoadStart={() => setLoading(true)} onLoadEnd={() => setLoading(false)} />*/}
            <Image {...props} style={[props.style, {width: imageWidth, height}]} />
        </>
    );
}
