import React from 'react';
import Button from '../Button';
import { Image, Share, StyleSheet } from 'react-native';
import StyleGuide from "../../../res/constants/StyleGuide";
import {setMenu} from "../../redux/actions/MenuActions";
import {connect} from "react-redux";

function ShareButton(props) {
    const translations = props.translations[props.translations.currentLanguage];

    return (
        <Button
            onPress={() => {
                Share.share({
                    message: translations.general.shareMessage,
                }).then(() => {
                    console.log('SHARED');
                }).catch(err => {
                    console.log('SHARE ERROR', err);
                });
            }}
            label={''}
            wrapperStyle={styles.buttonWrapper}
            style={styles.buttonText}
            beforeText={<Image source={require('../../../res/images/share-icon.png')} style={{width: 35, height: 35, marginRight: 5,}} />}
        />
    );
}

const styles = StyleSheet.create({
    buttonWrapper: {
        width: 30 + '%',
        height: StyleGuide.buttons.height,
    },
});

const mapStateToProps = state => {
    return {
        menuState: state.MenuReducer,
        translations: state.TranslateReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (payload) => dispatch(setMenu(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
