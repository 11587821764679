import 'react-native-gesture-handler';
import React, {useState, useRef} from 'react';
import { Provider } from 'react-redux';
import {
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import * as firebase from 'firebase';
import {
    useFonts,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_600SemiBold,
    Montserrat_800ExtraBold,
} from "@expo-google-fonts/montserrat";
import configureStore from "./src/redux/reducers/configureStore";
import NavigatorWrapper from "./src/NavigatorWrapper";

// Initialize Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyBhwDonz7ld-ORI64-IVizegMYSPGN0nr4',
    authDomain: 'chaava-dev.firebaseapp.com',
    databaseURL: 'https://chaava-dev.firebaseio.com',
    projectId: 'chaava-dev',
    storageBucket: 'chaava-dev.appspot.com',
    messagingSenderId: '802653113193',
    // appId: 'app-id',
    // measurementId: 'G-measurement-id',
};

const store = configureStore();

export default function App() {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    let [fontsLoaded] = useFonts({
        Montserrat_300Light,
        Montserrat_400Regular,
        Montserrat_600SemiBold,
        Montserrat_800ExtraBold,
    });

    if (!fontsLoaded) {
        return (<ActivityIndicator size={'large'} color={'#E42E23'}/>);
    }

    // Create unique uuid for the device and save it to the LocalStorage

    return (
        <Provider store={store}>
            <NavigatorWrapper />
        </Provider>
    )
}

const styles = StyleSheet.create({
    mainBackground: {
        backgroundColor: '#53ADC8',
    }
});
