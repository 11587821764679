const INITIAL_STATE = {
    showMenu: false,
};

const MenuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_MENU":
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export default MenuReducer;
